import * as React from "react"
import * as styles from './styles.module.scss'
import cancelIcon from '../../svg/cancel.svg'
import rightArrow from '../../svg/right-arrow.svg'
import CustomCarousel from "../CustomCarousel/CustomCarousel"

export default function WorkModal(data) {
  const { featuredImage, imageGallery, artistName, countryAndTime, material, dimension, description } = data.content.content
  const detailTitle = data.content.content.details ? data.content.content.details.title : null
  const detailDescription = data.content.content.details ? data.content.content.details.description : null
  const detailImageGallery = data.content.content.details ? data.content.content.details.imageGallery : null
  const detailPrice = data.content.content.details ? data.content.content.details.price : null
  const detailLink = data.content.content.details ? data.content.content.details.link : null
  const isOnSale = data.content.content.onSale ? true : false

  const handleModalClose = () => {
    setTimeout(() => {
      data.modalClose()
    }, 0);
  }

  return (
    <div data-type='modalBackground' className={`${styles.root}`} style={{display: data.visible ? 'block' : 'none'}}>
      <div className={styles.cancelIcon} onClick={handleModalClose}>
        <img data-type="icon" src={cancelIcon} />
      </div>
      <div className={`${styles.rootInner} grid-parent`}>
        {
          data.type === 'artistWork' ?
          <div className={styles.imageGallery}>
            <CustomCarousel
              images={[featuredImage]}
              showImageTitle={data.type === 'artistWork' ? true : false}
              type='artistWork'
            />
          </div> :
          imageGallery ?
          <div className={styles.imageGallery}>
            <CustomCarousel
              images={imageGallery}
              showImageTitle='true'
              info={imageGallery}
            />
          </div> :
          detailImageGallery ?
          <div className={styles.imageGallery}>
            <CustomCarousel
              images={detailImageGallery}
              showImageTitle='true'
              showImageTitle={data.type === 'artistWork' ? true : false}
            />
          </div> :
          null
        }

          <div className={`${styles.side} ${data.content.content.details}`}>
            <div className={styles.artistName}>{artistName || detailTitle}</div>
            <div className={styles.countryAndTime}>{countryAndTime}</div>
            <div className={styles.material}>{material}</div>
            <div className={styles.dimension}>{dimension}</div>
            {description ?
              <div className={styles.body}>{description.childMarkdownRemark.rawMarkdownBody}</div>
            : null}
            {detailDescription ?
              <div className={styles.body} dangerouslySetInnerHTML={{ __html: detailDescription.childMarkdownRemark.html }}></div>
            : null}
            {isOnSale ?
              <div className={styles.price}>USD {detailPrice}</div>
            : null}

            {
            data.type === 'inquery' ?
              <div className={styles.inquire}><a href="mailto:hello@rainraingallery.com">Inquire</a></div> :
            data.type === 'artistWork' ?
              null :
            isOnSale ?
              <div className={styles.inquire}>
                <a href="mailto:hello@rainraingallery.com">
                  Buy <span><img src={rightArrow} /></span>
                </a>
              </div> :
              <div className={styles.inquire}><a href={detailLink} target='_blank'>Read</a></div>
            }
          </div>
      </div>
    </div>
  )
}