import React, { useState, useEffect } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import './publications.scss'
import ExhibitionPreview from '../../components/exhibitionPreview/exhibitionPreview'

const Publications = ({ pageContext }) => {
  const data = pageContext.content

  const [years, setYears] = useState([])
	const [types, setTypes] = useState([])
  const [activeYearIndex, setActiveYearIndex] = useState(0)
  const [activeTypeIndex, setActiveTypeIndex] = useState(0)
  const [activeYear, setActiveYear] = useState('All')
	const [activeType, setActiveType] = useState('All')
  const [contentData, setContentData] = useState([])
  const [yearListExbanded, setYearListExbanded] = useState(false)
  const [typeListExbanded, setTypeListExbanded] = useState(false)

  const compare = (a, b) => {
    if (a.node.order > b.node.order) {
      return -1;
    } else {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    setContentData(data)
		getAllYears()
    getAllTypes()
	}, [])

  useEffect(() => {
		applyFilters()
	}, [activeYear, activeType])

  const getAllYears = () => {
		let arr = ['All']
		pageContext.content.forEach(el => {
			if(arr.includes(el.node.year)) {
				return
			}
			arr.push(el.node.year)
		})
		setYears(arr.sort().reverse())
	}

	const getAllTypes = data => {
		let arr = ['All']
		pageContext.content.forEach(el => {
			if(arr.includes(el.node.type)) {
				return
			}
			arr.push(el.node.type)
		})
		setTypes(arr.sort())
	}

  const handleFilterYear = year => {
		setActiveYear(year)
    setActiveYearIndex(years.indexOf(year))
	}

	const handleFilterType = type => {
		setActiveType(type)
    setActiveTypeIndex(types.indexOf(type))
	}

  const applyFilters = () => {
		const newData = data.filter(x => activeYear==='All' ? x : x.node.year === activeYear).filter(x => activeType==='All' ? x : x.node.type === activeType)
		setContentData(newData)
	}

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
  }

  return (
    <Layout>
      <Seo title="Publications" />

      <div className='publications'>
        <div className='publications__filter'>
          <div className='title'>Sort by</div>
          <div className='filters'>
            <div className='year-wrapper'>
              <div className='title' onClick={() => setYearListExbanded(!yearListExbanded)}>Year</div>
              <div className='years grid-parent' style={{display: yearListExbanded ? 'grid' : 'none'}}>
                {
                  sliceIntoChunks(years, 5).map((row, rowIndex) => (
                    <div className="row">
                      {
                        row.map((year, index) => <div key={index} className='year' onClick={() => handleFilterYear(year)}><li className={`${activeYearIndex === index + 5*rowIndex ? 'active' : null}`}>{year}</li></div>)
                      }
                    </div>
                  ))
                }
              </div>
            </div>

            <div className='type-wrapper'>
              <div className='title' onClick={() => setTypeListExbanded(!typeListExbanded)}>Type</div>
              <div className='types' style={{display: typeListExbanded ? 'block' : 'none'}}>
                {
                  types.map((type, index) => <div key={index} className='type' onClick={() => handleFilterType(type)}><li className={`${activeTypeIndex === index ? 'active' : null}`}>{type}</li></div>)
                }
              </div>
            </div>
          </div>
        </div>

        <div className="grid-parent publications__content">
          {contentData.sort(compare).map(node => {
            return (
              <ExhibitionPreview
                content={node.node}
                type='3-up'
                publication='true'
                key={node.node.slug}
                fixedSize={true}
                hasModal={node.node.onSale}
                modalContent={node.details}
              />
            )
          }
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Publications
