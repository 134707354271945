// extracted by mini-css-extract-plugin
export var artistName = "styles-module--artistName--035c6";
export var body = "styles-module--body--9abc2";
export var cancelIcon = "styles-module--cancelIcon--3f834";
export var countryAndTime = "styles-module--countryAndTime--875af";
export var detailImageGallery = "styles-module--detailImageGallery--31127";
export var dimension = "styles-module--dimension--22d2c";
export var imageContainer = "styles-module--imageContainer--d58df";
export var imageGallery = "styles-module--imageGallery--a3252";
export var inquire = "styles-module--inquire--117e9";
export var isHidden = "styles-module--isHidden--91e04";
export var isVisible = "styles-module--isVisible--09375";
export var material = "styles-module--material--607cc";
export var price = "styles-module--price--9d283";
export var root = "styles-module--root--b8541";
export var rootInner = "styles-module--rootInner--e7d12";
export var side = "styles-module--side--54708";