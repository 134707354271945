import * as React from "react"
import { useState } from 'react'
import { Link } from 'gatsby'
import * as styles from './styles.module.css'
import rightArrow from '../../svg/right-arrow.svg'
import downArrow from '../../svg/down-arrow.svg'

import WorkModal from '../../components/workModal/workModal'

const ExhibitionPreview = data => {
  const [isVisible, setIsVisible] = useState(false)

  const handleOpenModal = () => {
    if(!data.hasModal) {
      return
    }

    setIsVisible(true)
    document.body.style.overflow = 'hidden'
  }

  const handleModalClose = () => {
    setIsVisible(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <div
      className={data.width ? 'grid-parent' : data.type==='3-up' ? 'threeup' : data.type==='3-up-skip' ? 'threeupskip' : null}
      style={{cursor: data.publication ? 'unset' : 'pointer'}}
      onClick={data.publication ? null : handleOpenModal}
      onKeyDown={data.publication ? null : handleOpenModal}
      key={data.content.slug}
      role = "button"
      tabIndex={0}
    >
      {data.externalURL ?
        <a href={data.externalURL} className={data.width ? `grid-child-${data.width}` : null} rel="noreferrer" target={data.newTab ? "_blank" : null}>
          {data.content.featuredImage ? data.fixedSize ? <div className={styles.bgImage} style={{backgroundImage: `url(${data.content.featuredImage.file.url})`}}></div> : <img style={{width: '100%'}} src={data.content.featuredImage.file.url} /> : null}
          <div style={{fontSize: `${data.fontSize}`}} className={styles.title}>{data.content.title || data.content.artistName}</div>
          <div style={{fontSize: `${data.fontSize}`}} className={styles.date}>{data.content.date}</div>
          {data.content.countryAndTime ? <div>{data.content.countryAndTime}</div> : null}
          {data.content.material ? <div>{data.content.material}</div> : null}
          {data.content.dimension ? <div>{data.content.dimension}</div> : null}
        </a>
      :
        <Link className={data.width ? `grid-child-${data.width}` : data.publication ? styles.linkDisabled : 'link'} to={data.url}>
          {data.content.featuredImage ? data.fixedSize ? <div className={styles.bgImage} style={{backgroundImage: `url(${data.content.featuredImage.file.url})`}}></div> : <img style={{width: '100%'}} src={data.content.featuredImage.file.url} /> : null}
          <div className={styles.title}>{data.content.title || data.content.artistName}</div>
          <div className={styles.date}>{data.content.date}</div>
          {
            data.publication ?
              data.content.onSale ?
            <div
              className={styles.button}
              onClick={handleOpenModal}
            >
              Buy
              <span>
                <img src={rightArrow}/>
              </span>
            </div> :
            <a
              className={styles.button}
              href={data.content.pdf.file.url}
              target='_blank'
            >
              Read
              <span>
                <img data-type='icon' src={downArrow}/>
              </span>
            </a> :
            null
          }
          {data.content.countryAndTime ? <div className={styles.countryAndTime}>{data.content.countryAndTime}</div> : null}
          {data.content.material ? <div className={styles.material}>{data.content.material}</div> : null}
          {data.content.dimension ? <div className={styles.dimension}>{data.content.dimension}</div> : null}
        </Link>
    }

      <WorkModal
        visible={isVisible}
        content={data}
        modalClose={handleModalClose}
        type={data.workType}
        showImageTitle={data.carouselShowImagwTitle}
      />
    </div>
  )
}

export default ExhibitionPreview
